var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.journalData,"loading":_vm.loadingJournal,"headers":_vm.headers,"hide-default-footer":"","dense":"","sort-by":"created","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function({ pagination, options }){return [_c('v-toolbar',{attrs:{"flat":"","outlined":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.prevDate}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(" "+_vm._s(_vm.displayDate)+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"color":"green accent-4"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.showNext},on:{"click":function($event){return _vm.nextDate()}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),(pagination.pageCount > 1)?[_c('v-btn',{attrs:{"icon":"","disabled":pagination.page == 1},on:{"click":function($event){options.page--}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_vm._v(" "+_vm._s(pagination.page)+" of "+_vm._s(pagination.pageCount)+" "),_c('v-btn',{attrs:{"icon":"","disabled":pagination.page == pagination.pageCount},on:{"click":function($event){options.page++}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","color":"green accent-4","dark":""},on:{"click":function($event){_vm.editDialog = {
                visible: true,
                entry: { food: '', created: '' },
                title: 'New Entry',
                buttonText: 'Create',
              }}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]:_vm._e()],2)]}},{key:`item.created`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:`item.tools`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editDialog = {
            visible: true,
            title: 'Edit Entry',
            buttonText: 'Update',
            entry: { ...item },
          }}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEntry({ id: item._id, $confirm: _vm.$confirm })}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('edit-entry',{attrs:{"edit-dialog":_vm.editDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }