module.exports = ({ state, commit }, { id, $confirm }) => {
  $confirm("Are you sure you want to delete this entry?").then(response => {
    if (response) {
      console.log({ deleteEntry: { id } });
      state
        .wretch("/api/delete-entry")
        .formData({ _id: id, token: state.token })
        .post()
        .json()
        .then(state.handleResponse)
        .then(() => {
          state.journalData = state.journalData.filter(({ _id }) => _id != id);
        })
        .catch(message => commit("setError", message));
    }
  });
};
