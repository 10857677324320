<template>
  <v-app>
    <v-app-bar app color="green accent-4" dark>
      <v-app-bar-title> My Diet Journal </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn icon to="/settings">
        <v-icon> mdi-cogs </v-icon>
      </v-btn>
      <template v-if="token">
        <v-btn icon @click="handleSignOut">
          <v-icon> mdi-logout </v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="snack.visible" :color="snack.color">
      {{ snack.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "App",

    data: () => ({
      //
    }),

    computed: {
      ...mapState(["snack", "token"]),
    },

    methods: {
      ...mapActions(["signOut"]),
      handleSignOut() {
        this.signOut().then(this.$router.push("/"));
      },
    },
  };
</script>
