<template>
  <div class="pt-12">
    <v-img src="@/assets/login-background.png" height="65vh" position="center">
      <v-card color="rgba(255,255,255,0.9)" height="100vh">
        <v-container>
          <v-form v-model="validForm">
            <v-card-text>
              <v-text-field
                type="email"
                v-model="email"
                label="E-Mail"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-text-field
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                label="Password"
                :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass = !showPass"
                :rules="[rules.required]"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-checkbox label="Remember Me" v-model="rememberMe"></v-checkbox>
              <!-- <v-btn text to="/forgot">Forgot Password?</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn text @click="handleSignIn" :disabled="!validForm"
                >Sign In</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-img>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    data: () => ({
      email: "",
      password: "",
      showPass: false,
      validForm: false,
      rememberMe: false,
      rules: {
        required: (v) => !!v || "Required",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid e-mail",
      },
    }),
    methods: {
      ...mapActions(["signIn"]),
      handleSignIn() {
        this.signIn({
          email: this.email,
          password: this.password,
          remember: this.rememberMe,
        }).then((response) => {
          if (response) {
            this.$router.push("/journal");
          }
        });
      },
    },
  };
</script>