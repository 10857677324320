import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import wretch from "wretch";
import handleResponse from "@/assets/handle-response";

export default new Vuex.Store({
  state: {
    handleResponse,
    journalData: [],
    loadingJournal: false,
    token:
      localStorage.getItem("diet-journal-token") ||
      sessionStorage.getItem("diet-journal-token") ||
      false,
    snack: {
      visible: false,
      color: "",
      text: ""
    },
    wretch
  },
  mutations: {
    setError(state, message) {
      state.snack = {
        visible: true,
        color: "error",
        text: message
      };
    }
  },
  actions: {
    verifyToken: require("./actions/verify-token"),
    signIn: require("./actions/sign-in"),
    updatePassword: require("./actions/update-password"),
    signOut: require("./actions/sign-out"),
    getJournalData: require("./actions/get-journal-data"),
    deleteEntry: require("./actions/delete-entry"),
    updateEntry: require("./actions/update-entry")
  },
  modules: {}
});
