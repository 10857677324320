<template>
  <div>
    mc-todo: Password Reset

    <router-link to="/">Sign In</router-link>
  </div>
</template>

<script>
  export default {};
</script>