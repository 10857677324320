<template>
  <div>
    <v-data-table
      :items="journalData"
      :loading="loadingJournal"
      :headers="headers"
      hide-default-footer
      dense
      sort-by="created"
      sort-desc
    >
      <template #top="{ pagination, options }">
        <v-toolbar flat outlined>
          <v-btn icon @click="prevDate">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>

          <v-menu offset-y>
            <template #activator="{ on }">
              <div v-on="on" style="cursor: pointer">
                {{ displayDate }}
              </div>
            </template>
            <v-date-picker
              v-model="selectedDate"
              :max="maxDate"
              color="green accent-4"
            ></v-date-picker>
          </v-menu>

          <v-btn icon @click="nextDate()" :disabled="!showNext">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <template v-if="pagination.pageCount > 1">
            <v-btn
              icon
              @click="options.page--"
              :disabled="pagination.page == 1"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>

            {{ pagination.page }} of {{ pagination.pageCount }}

            <v-btn
              icon
              @click="options.page++"
              :disabled="pagination.page == pagination.pageCount"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              fab
              small
              color="green accent-4"
              dark
              @click="
                editDialog = {
                  visible: true,
                  entry: { food: '', created: '' },
                  title: 'New Entry',
                  buttonText: 'Create',
                }
              "
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </v-toolbar>
      </template>

      <template #[`item.created`]="{ item }">
        {{ formatDate(item.created) }}
      </template>

      <template #[`item.tools`]="{ item }">
        <v-btn
          icon
          @click="
            editDialog = {
              visible: true,
              title: 'Edit Entry',
              buttonText: 'Update',
              entry: { ...item },
            }
          "
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>

        <v-btn icon @click="deleteEntry({ id: item._id, $confirm })">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <edit-entry :edit-dialog="editDialog"></edit-entry>
  </div>
</template>

<script>
  import { format, formatISO9075, parseISO, sub, add } from "date-fns";
  import { mapActions, mapState } from "vuex";
  import EditEntry from "@/components/EditEntry";

  export default {
    components: { EditEntry },

    data: () => ({
      editDialog: {
        visible: false,
        entry: {},
      },
      selectedDate: formatISO9075(new Date(), { representation: "complete" }),
      maxDate: formatISO9075(new Date(), { representation: "complete" }),
      headers: [
        {
          text: "Food",
          value: "food",
        },
        {
          text: "Logged",
          value: "created",
        },
        {
          text: "",
          value: "tools",
        },
      ],
    }),
    computed: {
      ...mapState(["journalData", "loadingJournal"]),

      displayDate() {
        return format(parseISO(this.selectedDate), "LLL d, yyyy");
      },
      showNext() {
        return (
          formatISO9075(parseISO(this.selectedDate), { representation: "date" }) <
          formatISO9075(new Date(), { representation: "date" })
        );
      },
    },
    watch: {
      selectedDate() {
        this.getJournalData(this.selectedDate);
      },
    },
    methods: {
      ...mapActions(["getJournalData", "deleteEntry"]),
      prevDate() {
        this.selectedDate = formatISO9075(
          sub(parseISO(this.selectedDate), { days: 1 }),
          { representation: "complete" }
        );
      },
      nextDate() {
        this.selectedDate = formatISO9075(
          add(parseISO(this.selectedDate), { days: 1 }),
          { representation: "complete" }
        );
      },
      formatDate(date) {
        return format(parseISO(date), "h:mm aa");
      },
    },
    mounted() {
      this.getJournalData(this.selectedDate);
    },
  };
</script>