module.exports = ({ state }) => {
  state;
  return state.token
    ? state
        .wretch("/api/verify-token")
        .formData({ token: state.token })
        .post()
        .json()
        .then(state.handleResponse)
        .catch(console.error)
    : false;
};
