import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import Forgot from "@/views/Forgot";
import store from "../store";
import Settings from "@/views/Settings";
import Journal from "@/views/Journal";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Sign In",
    component: SignIn
  },
  {
    path: "/forgot",
    name: "Forgot Password",
    component: Forgot
  },
  {
    path: "/journal",
    name: "Journal",
    component: Journal,
    meta: { requireAuth: true }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requireAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const valid = await store.dispatch("verifyToken");

  if (to.meta.requireAuth && !valid) {
    next("/");
    return;
  }

  if (to.path == "/" && valid) {
    next("/journal");
    return;
  }

  next();
});

export default router;
