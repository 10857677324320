module.exports = ({ state, commit }, editDialog) => {
  state
    .wretch("/api/update-entry")
    .formData({ entry: JSON.stringify(editDialog.entry), token: state.token })
    .post()
    .json()
    .then(state.handleResponse)
    .then(({ _id }) => {
      if (!editDialog.entry._id) {
        const entry = { ...editDialog.entry, _id };
        state.journalData.push(entry);
        editDialog.visible = false;
        return;
      }

      state.journalData = state.journalData.map(entry => {
        return entry._id == editDialog.entry._id ? editDialog.entry : entry;
      });
      editDialog.visible = false;
    })
    .catch(message => commit("setError", message));
};
