module.exports = ({ state,commit }, { email, password, remember }) => {
  return state
    .wretch("/api/sign-in")
    .formData({
      email,
      password
    })
    .post()
    .json()
    .then(state.handleResponse)
    .then(({ token }) => {
      if (remember) localStorage.setItem("diet-journal-token", token);
      if (!remember) sessionStorage.setItem("diet-journal-token", token);

      state.token = token;
      return true;
    })
    .catch(message => commit("setError", message));
};
