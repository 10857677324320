const { formatISO9075, parseISO } = require("date-fns");

module.exports = ({ state, commit }, date) => {
  date = formatISO9075(parseISO(date), { representation: "date" });
  state.loadingJournal = true;
  state
    .wretch(`/api/get-journal-data/${state.token}/${date}`)
    .get()
    .json()
    .then(state.handleResponse)
    .then(journal => {
      state.journalData = journal.data;
      state.loadingJournal = false;
    })
    .catch(message => commit("setError", message));
};
