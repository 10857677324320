import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({});
import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
