module.exports = ({ state, commit }, { oldPass, newPass }) => {
  state, oldPass, newPass;

  return state
    .wretch("/api/update-password")
    .formData({ oldPass, newPass, token: state.token })
    .post()
    .json()
    .then(state.handleResponse)
    .catch(message => commit("setError", message));
};
