module.exports = ({ state, commit }) => {
  return state
    .wretch(`/api/sign-out`)
    .formData({ token: state.token })
    .post()
    .json()
    .then(state.handleResponse)
    .then(() => {
      localStorage.removeItem("diet-journal-token");
      sessionStorage.removeItem("diet-journal-token");
      state.token = false;
    })
    .catch(message => commit("setError", message));
};
