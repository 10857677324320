<template>
  <div>
    <v-dialog v-model="editDialog.visible">
      <v-card>
        <v-card-title> {{ editDialog.title }} </v-card-title>
        <v-card-text>
          <v-text-field
            type="text"
            v-model="editDialog.entry.food"
            label="Food"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            type="text"
            v-model="editDialog.entry.created"
            label="Logged"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog.visible = false">Cancel</v-btn>
          <v-btn text @click="updateEntry(editDialog)">
            {{ editDialog.buttonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: ["editDialog"],
    methods: { ...mapActions(["updateEntry"]) },
  };
</script>