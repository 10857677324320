<template>
  <div>
    <v-container>
      <v-btn fab absolute right small color="primary" to="/journal">
        <v-icon> mdi-book </v-icon>
      </v-btn>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card outlined tile>
            <v-card-title> Change Password </v-card-title>
            <v-card-text>
              <v-text-field
                label="Current Password"
                v-model="oldPass"
                :type="showOld ? 'text' : 'password'"
                :append-icon="showOld ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showOld = !showOld"
              ></v-text-field>

              <v-text-field
                label="New Password"
                v-model="newPass"
                :type="showNew ? 'text' : 'password'"
                :append-icon="showNew ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showNew = !showNew"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="handlePasswordChange">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    data: () => ({
      showOld: false,
      showNew: false,
      oldPass: "",
      newPass: "",
    }),

    methods: {
      ...mapActions(["updatePassword"]),
      handlePasswordChange() {
        this.updatePassword({
          oldPass: this.oldPass,
          newPass: this.newPass,
        }).then(console.log);
      },
    },
  };
</script>